<template>
  <v-snackbar
    close-on-content-click
    location="top"
    v-model="newStoryStore.snackbar"
    :timeout="1750"
    :color="newStoryStore.postSuccess == true ? 'success' : 'error'"
  >
    {{
      newStoryStore.postSuccess == true
        ? "Story posted"
        : "Failed to post story"
    }}
    <template v-slot:actions>
      <v-btn
        color="white"
        variant="text"
        @click="newStoryStore.snackbar = false"
      >
        Close
      </v-btn>
    </template>
  </v-snackbar>
  <v-snackbar
    close-on-content-click
    location="top"
    v-model="snackBarStore.snackbar"
    :timeout="snackBarStore.timeout"
    :color="snackBarStore.color"
    @click="
      snackBarStore.url
        ? redirectToStoryPage(
            snackBarStore.extractStoryIdFromUrl(snackBarStore.url),
          )
        : null
    "
    :style="{ cursor: snackBarStore.url ? 'pointer' : 'default' }"
  >
    {{ snackBarStore.message }}
    <template v-slot:actions>
      <v-btn
        color="white"
        variant="text"
        @click.stop="snackBarStore.snackbar = false"
      >
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { useNewStoryStore } from "@/stores/newStoryStore";
import { useSnackBarStore } from "@/stores/snackBarStore";
import { useRouter } from "vue-router";

const componentName = "SnackBar";

export default {
  setup() {
    const router = useRouter();
    const newStoryStore = useNewStoryStore();
    const snackBarStore = useSnackBarStore();

    const redirectToStoryPage = (storyId) => {
      const functionName = "redirectToStoryPage";
      console.info(componentName, functionName);

      if (!storyId) {
        console.error(componentName, functionName, "StoryId not provided");
        return;
      }

      router.push({
        name: "posts",
        params: {
          postId: storyId,
        },
      });
    };

    return { newStoryStore, snackBarStore, redirectToStoryPage };
  },
};
</script>

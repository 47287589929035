import { auth } from "@/firebase";
import { onAuthStateChanged } from "firebase/auth";
import { defineStore } from "pinia";
import { ref } from "vue";
import { useRoute, useRouter } from "vue-router";

const storeName = "authStore";

export const useAuthStore = defineStore({
  id: "auth",
  state: () => ({
    router: useRouter(),
    route: useRoute(),
    user: ref(null),
    userId: ref(null), // For identifying user with anonymous authentication
    username: ref(null), // Display name
    savedPath: null,
    initialized: ref(false),
    firestoreListener: ref(null),
  }),
  actions: {
    async onInit() {
      const functionName = "onInit";
      console.info(storeName, functionName);
      console.debug(storeName, functionName, "Using production variables");

      this.username = localStorage.getItem("username");
      this.userId = localStorage.getItem("userId");

      console.debug(
        storeName,
        functionName,
        "username: ",
        this.username,
        ", userId: ",
        this.userId,
      );

      // watch(
      //   () => [this.user, this.userId, this.username, this.initialized],
      //   (
      //     [newUser, newUserId, newUsername, newInitialized],
      //     [oldUser, oldUserId, oldUsername, oldInitialized],
      //   ) => {
      //     console.debug(
      //       storeName,
      //       functionName,
      //       "newUser:",
      //       newUser,
      //       "oldUser:",
      //       oldUser,
      //       "newUserId:",
      //       newUserId,
      //       "oldUserId:",
      //       oldUserId,
      //       "newUsername:",
      //       newUsername,
      //       "oldUsername:",
      //       oldUsername,
      //       "newInitialized:",
      //       newInitialized,
      //       "oldInitialized:",
      //       oldInitialized,
      //     );
      //     if (newInitialized) {
      //       if (!newUser && !newUserId && !newUsername) {
      //         this.router.replace({ name: "auth" });
      //       }
      //     }
      //   },
      //   { immediate: false },
      // );

      onAuthStateChanged(auth, (user) => {
        console.debug(
          storeName,
          functionName,
          "onAuthStateChanged, user: ",
          user,
        );

        this.setUser(user);

        if (!user) {
          /**
           * User not logged in via Firebase
           */
          this.userId = null;
          this.username = null;
        }

        if (!this.initialized) this.initialized = true;
      });
    },
    isSubstringInArray(string, array) {
      // Loop through the array
      for (let value of array) {
        // Check if the string includes the array value as a substring
        if (string.includes(value)) {
          return true; // If a substring is found, return true
        }
      }
      return false; // If no substring is found, return false
    },
    setUser(user) {
      const functionName = "setUser";
      this.user = user;
      console.debug(storeName, functionName, "Updated User-object to: ", user);
    },
    setUserId(userId) {
      const functionName = "setUserId";
      this.userId = userId;
      localStorage.setItem("userId", userId);
      console.debug(
        storeName,
        functionName,
        `Updated User ID to '${this.userId}'`,
      );
    },
    setUsername(username) {
      const functionName = "setUsername";
      this.username = username;
      localStorage.setItem("username", username);
      console.debug(
        storeName,
        functionName,
        `Updated username to '${this.username}'`,
      );
    },
    savePath(path) {
      this.savedPath = path;
    },
    clearSavedPath() {
      this.savedPath = null;
    },
    async logout() {
      const functionName = "logout";
      console.info(storeName, functionName);

      try {
        await auth.signOut();
        this.userId = null;
        this.username = null;
        this.user = null;
        localStorage.removeItem("username");
        localStorage.removeItem("userId");
        console.debug(storeName, functionName, "User logged out successfully");
        this.router.replace({ name: "auth" });
      } catch (error) {
        console.error(error);
      }
    },
  },
});

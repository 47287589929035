<template>
  <v-bottom-navigation
    fixed
    v-if="displayNavBar"
    :elevation="0"
    mode="shift"
    v-model="activeTab"
    :style="{
      borderTop: '1px solid lightgrey',
      opacity: displayNavBar ? 1 : 0,
      pointerEvents: displayNavBar ? 'all' : 'none',
    }"
    height="48"
  >
    <v-btn
      v-for="tab in tabs"
      :key="tab.value"
      :value="tab.value"
      :to="tab.route"
      @click="changeTab(tab.value)"
    >
      <v-icon>{{ tab.icon }}</v-icon>
      <span>{{ tab.text }}</span>
    </v-btn>
  </v-bottom-navigation>
</template>

<script>
import { useDimenStore } from "@/stores/dimenStore";
import { onMounted, ref, watch } from "vue";
import { useRoute } from "vue-router";

const componentName = "BottomNavigation";

export default {
  name: "BottomNavigation",
  setup() {
    const dimenStore = useDimenStore();
    const displayNavBar = ref(false);

    const route = useRoute();

    const activeTab = ref(0);
    const tabs = [
      // { value: "home", text: "Home", icon: "mdi-bookshelf", route: "/" },
      {
        value: "create",
        text: "Create",
        icon: "mdi-plus-box-outline",
        route: "/posts/create",
      },
      {
        value: "profile",
        text: "Profile",
        icon: "mdi-account",
        route: "/profile",
      },
    ];
    const changeTab = (tabValue) => {
      const tabIndex = tabs.findIndex((obj) => obj.value === tabValue);
      if (tabIndex != -1) {
        activeTab.value = tabIndex; // Change the active tab when clicked
      }
    };

    // Check if current route requires v-bottom-navigation to be displayed -->
    watch(
      () => route.meta,
      (newMeta) => {
        console.debug(componentName, "newMeta:", newMeta);
        displayNavBar.value = newMeta.displayBotNav || false;

        // Try to get v-bottom-navigation's height in case it's not already done
        if (!dimenStore.vBotNavHeight) {
          try {
            const computedStyle = window.getComputedStyle(
              document.querySelector(".v-bottom-navigation"),
            );

            dimenStore.setVBotNavHeight(parseInt(computedStyle.height));
          } catch (error) {
            console.error(error);
          }
        }
      },
      { flush: "pre", immediate: true, deep: false },
    );
    // <--

    // Get current route's name so it can be automatically activated in case app is just launched in specific route -->
    watch(
      () => route.name,
      (newRouteName) => {
        console.debug(componentName, "newRouteName:", newRouteName);
        const index = tabs.findIndex((tab) => tab.value === newRouteName);
        console.debug(componentName, "name", "index:", index);
        if (index !== -1) {
          activeTab.value = index;
        }
      },
      { flush: "pre", immediate: true, deep: false },
    );
    // <--

    onMounted(async () => {
      const functionName = "onMounted";
      console.info(componentName, functionName);
    });

    return { displayNavBar, activeTab, tabs, changeTab };
  },
};
</script>

<style>
.firebase-emulator-warning {
  background-color: transparent !important;
  border: none !important;
  color: rgba(245, 66, 66, 0.3) !important;
  pointer-events: none;
}
</style>

import { defineStore } from "pinia";
import { ref } from "vue";

const storeName = "appBarStore";

export const useAppBarStore = defineStore({
  id: storeName,
  state: () => ({
    title: ref(null),
    back: ref(null),
    contentCreatorName: ref(null),
    actions: ref([]),
  }),
  actions: {
    onInit() {
      const functionName = "onInit";
      console.info(storeName, functionName);
    },
  },
});

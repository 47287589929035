import { ref, watch } from "vue";
import { defineStore } from "pinia";
import { firestore } from "@/firebase";
import {
  collection,
  limit,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import { useAuthStore } from "./authStore";

const storeName = "devicesStore";

export const useDevicesStore = defineStore({
  id: storeName,
  state: () => ({
    linkedDevicesDocs: ref([]),
    linkedDevicesSnapshotListener: ref(null),
    emailSnapshotListener: ref(null),
    emailVerified: ref(null),
    authStore: useAuthStore(),
    initialized: ref(false),
  }),
  actions: {
    onInit() {
      const functionName = "onInit";
      console.info(storeName, functionName);

      watch(
        () => this.authStore.userId,
        (newUserId) => {
          console.info(storeName, functionName, "newUserId:", newUserId);
          if (newUserId != null) {
            console.debug(storeName, functionName, "User logged in");
            this.createLinkedDevicesSnapshotListener();
            this.createEmailVerifiedSnapshotListener();
          }

          this.initialized = true;
        },
        { immediate: true },
      );
    },
    createEmailVerifiedSnapshotListener() {
      const functionName = "createEmailVerifiedSnapshotListener";
      console.info(storeName, functionName);

      const q = query(
        collection(firestore, "emailVerifications"),
        where("userId", "==", this.authStore.userId),
        limit(1),
      );

      this.emailSnapshotListener = onSnapshot(q, (querySnapshot) => {
        if (querySnapshot.empty) {
          console.debug(
            storeName,
            functionName,
            `No emailVerification-document found with given userId: '${this.authStore.userId}'`,
          );
          this.emailVerified = false;
        } else {
          console.debug(
            storeName,
            functionName,
            `EmailVerification-document found with given userId: '${this.authStore.userId}'`,
          );

          const doc = querySnapshot.docs[0];
          const docData = doc.data();

          if (docData.email != "" && docData.verified) {
            console.debug(
              storeName,
              functionName,
              "User has a verified email address",
            );
            this.emailVerified = true;
          } else {
            console.debug(
              storeName,
              functionName,
              "User doesn't have a verified email address",
            );
            this.emailVerified = false;
          }
        }
      });
    },
    createLinkedDevicesSnapshotListener() {
      const functionName = "createLinkedDevicesSnapshotListener";
      console.info(storeName, functionName);

      const q = query(
        collection(firestore, "credentials"),
        where("userId", "==", this.authStore.userId),
        limit(5), // Allow max 5 devices per user
      );

      this.linkedDevicesSnapshotListener = onSnapshot(q, (querySnapshot) => {
        if (querySnapshot.empty) {
          console.debug(
            storeName,
            functionName,
            "No linked devices found for user",
          );
        } else {
          console.debug(
            storeName,
            functionName,
            `Linked devices found for user: ${querySnapshot.docs.length}`,
          );

          let docsData = [];

          querySnapshot.forEach((doc) => {
            let docData = doc.data();
            console.debug(storeName, functionName, doc.id, "==", docData);
            docsData.push(docData);
          });

          this.linkedDevicesDocs = [...docsData];
        }
      });
    },
  },
});

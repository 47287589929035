import { defineStore } from "pinia";
import { ref } from "vue";

const storeName = "dimenStore";

export const useDimenStore = defineStore({
  id: storeName,
  state: () => ({
    vContainerPadding: ref(null),
    vBotNavHeight: ref(null),
    vTopNavHeight: ref(null),
    isMobile: ref(null),
  }),
  actions: {
    setVContainerPadding(padding) {
      const functionName = "setVContainerPadding";
      this.vContainerPadding = padding;
      console.debug(
        storeName,
        functionName,
        "vContainerPadding: ",
        this.vContainerPadding,
      );
    },
    setVBotNavHeight(height) {
      const functionName = "setVBotNavHeight";
      this.vBotNavHeight = height;
      console.debug(
        storeName,
        functionName,
        "vBotNavHeight: ",
        this.vBotNavHeight,
      );
    },
    setVTopNavHeight(height) {
      const functionName = "setVTopNavHeight";
      this.vTopNavHeight = height;
      console.debug(
        storeName,
        functionName,
        "vTopNavHeight: ",
        this.vTopNavHeight,
      );
    },
    calculateTopNavHeight() {
      const functionName = "getAppBarHeight";
      console.info(storeName, functionName);

      try {
        const computedStyle = window.getComputedStyle(
          document.getElementsByClassName("v-toolbar")[0],
        );
        const appBarHeight = parseInt(computedStyle.height);
        console.debug(
          storeName,
          functionName,
          "v-app-bar's height:",
          appBarHeight,
        );
        this.vTopNavHeight = appBarHeight;
        return appBarHeight;
      } catch (error) {
        console.warn(storeName, functionName, error);
        return null;
      }
    },
    calculateIsMobile() {
      const functionName = "setIsMobile";
      console.info(storeName, functionName);

      this.isMobile = window.innerWidth <= 600;

      console.debug(storeName, functionName, "isMobile:", this.isMobile);
    },
    calculateBottomNavHeight() {
      const functionName = "calculateBottomNavHeight";
      console.info(storeName, functionName);

      // Need v-bottom-navigation's height so it won't overlap content -->

      try {
        const computedStyle = window.getComputedStyle(
          document.getElementsByClassName("v-bottom-navigation")[0],
        );

        const botNavHeight = parseInt(computedStyle.height);
        console.debug(storeName, functionName, "botNavHeight:", botNavHeight);
        this.vBotNavHeight = botNavHeight;
        return botNavHeight;
      } catch (error) {
        console.warn(storeName, functionName, error);
        return null;
      }
      // <--
    },
    calculateContainerHeight(isTopNavActive, isBotNavActive) {
      const functionName = "calculateContainerHeight";
      console.info(storeName, functionName);

      let containerHeight = window.innerHeight;

      if (isTopNavActive) {
        containerHeight -= this.calculateTopNavHeight();
      }

      if (isBotNavActive) {
        containerHeight -= this.calculateBottomNavHeight();
      }

      console.debug(
        storeName,
        functionName,
        "containerHeight:",
        containerHeight,
      );

      return containerHeight;
    },
  },
});

import { firestore } from "@/firebase";
import { addDoc, collection } from "firebase/firestore";

const functionName = "linkNewDeviceToUser";

/**
 *
 * @param {*} userId
 * @param {*} credential webAuthn credential
 * @param {*} defaultDeviceLinkingInfo app defined, OS - Browser - timestamp, e.g. "Windows, Chrome, 6/7/2024 9:51:11 AM"
 * @param {*} deviceName user defined, e.g. "iPhone 12 Mini 128Gb RED"
 * @returns bool
 */

export default async function (
  userId,
  credential,
  defaultDeviceLinkingInfo,
  deviceName,
) {
  console.info(functionName);
  console.debug(
    functionName,
    "userId: ",
    userId,
    ", credential: ",
    credential,
    ", deviceName: ",
    deviceName,
  );

  try {
    /**
     * Using credentials-collection for storing and linking devices credentials to users
     */

    const credentialsCollectionRef = collection(firestore, "credentials");

    const credentialsDocData = {
      algorithm: credential.algorithm,
      credentialId: credential.id,
      publicKey: credential.publicKey,
      userId: userId,
      defaultDeviceLinkingInfo: defaultDeviceLinkingInfo,
      deviceName: deviceName,
      createdAt: new Date().toUTCString(),
    };

    const credentialsDocRef = await addDoc(
      credentialsCollectionRef,
      credentialsDocData,
    );

    console.debug(
      functionName,
      "New device's credentials added to document: ",
      credentialsDocRef.id,
    );
    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
}

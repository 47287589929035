<template>
  <div
    :style="{
      display: 'flex',
      flexDirection: 'column',
      alignSelf: 'center',
      alignItems: 'center',
      justifyContent: 'center',
      boxSizing: 'border-box',
      width: '100%',
      height: `${containerHeight}px`,
    }"
  >
    <div>
      <p
        style="
          font-size: 15px;
          font-weight: bold;
          text-align: center;
          margin-bottom: 25px;
        "
      >
        Let's give your device a name so it's easier to identify
      </p>
      <v-text-field
        class="multi-line"
        :placeholder="'iPhone 12 Mini RED'"
        :disabled="isLinking == true"
        v-model="deviceName"
        clearable
        @click:clear="clearDeviceName()"
        label="Device name"
        counter
        maxlength="50"
        :rules="[validateText]"
        type="text"
        :style="{
          width: dimenStore.isMobile ? '100%' : '450px',
          height: 'fit-content',
          marginBottom: '25px',
        }"
      ></v-text-field>
      <div>
        <v-btn
          variant="flat"
          @click="verifyLinkingDevice"
          :color="
            linkingSuccess === null
              ? 'primary'
              : linkingSuccess === false
              ? 'error'
              : 'success'
          "
          :loading="isLinking"
          :disabled="inputError.length > 0 || deviceName?.length < 15"
        >
          <v-icon v-if="isLinking == false && linkingSuccess == true"
            >mdi-check</v-icon
          >
          <span v-else> Link Device</span>
          <template v-slot:loader>
            <v-progress-circular
              v-if="isLinking == true"
              indeterminate
            ></v-progress-circular>
          </template>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { auth, firestore } from "@/firebase"; // Import your Firebase configuration
import { deleteDoc, doc } from "firebase/firestore";
import { useAuthStore } from "@/stores/authStore";
import { useSnackBarStore } from "@/stores/snackBarStore";
import { useDimenStore } from "@/stores/dimenStore";
import getChallenge from "@/utils/access/getChallenge";
import webAuthnCreateCredential from "@/utils/access/webAuthnCreateCredential";
import { signInAnonymously } from "@firebase/auth";
import { onMounted, onUnmounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import linkNewDeviceToUser from "../utils/linkDevices/linkNewDeviceToUser";
import verifyDeviceLinkCode from "../utils/linkDevices/verifyDeviceLinkCode";

const componentName = "LinkDevicesVerifyView";

export default {
  name: componentName,
  components: {},
  setup() {
    const windowHeight = ref(window.innerHeight);
    const dimenStore = useDimenStore();
    const authStore = useAuthStore();
    const snackBarStore = useSnackBarStore();
    const deviceName = ref("");
    const defaultDeviceLinkingInfo = ref("");
    const router = useRouter();
    const route = useRoute();
    const isLinking = ref(false);
    const linkingSuccess = ref(null);
    const inputError = ref([]);

    const containerHeight = ref(null);

    const clearDeviceName = () => {
      const functionName = "clearDeviceName";
      console.info(componentName, functionName);
      deviceName.value = "";
    };

    const getDeviceInfo = () => {
      const userAgent = navigator.userAgent;
      let os = "Unknown OS";
      let browser = "Unknown Browser";

      // Detect OS
      if (userAgent.indexOf("Win") !== -1) os = "Windows";
      if (userAgent.indexOf("Mac") !== -1) os = "MacOS";
      if (userAgent.indexOf("X11") !== -1) os = "UNIX";
      if (userAgent.indexOf("Linux") !== -1) os = "Linux";
      if (/Android/.test(userAgent)) os = "Android";
      if (/iPhone|iPad|iPod/.test(userAgent)) os = "iOS";

      // Detect Browser
      if (/Chrome/.test(userAgent) && /Google Inc/.test(navigator.vendor)) {
        browser = "Chrome";
      } else if (
        /Safari/.test(userAgent) &&
        /Apple Computer/.test(navigator.vendor)
      ) {
        browser = "Safari";
      } else if (/Firefox/.test(userAgent)) {
        browser = "Firefox";
      } else if (/MSIE|Trident/.test(userAgent)) {
        browser = "Internet Explorer";
      } else if (/Edge/.test(userAgent)) {
        browser = "Edge";
      } else if (/Opera|OPR/.test(userAgent)) {
        browser = "Opera";
      }

      return `${os}, ${browser}, ${new Date().toLocaleDateString()} ${new Date().toLocaleTimeString()}`;
    };

    const validateText = (value) => {
      const functionName = "validateText";

      if (!value || value.trim().length == 0) {
        inputError.value = [];
        return true;
      }

      let errorMessage = "";

      let regex = /^[A-Za-z]/; // Allow only letters as first character
      errorMessage = "First character has to be a letter";
      if (!regex.test(value)) {
        if (inputError.value.indexOf(errorMessage) === -1)
          inputError.value.push(errorMessage);
      } else if (inputError.value.indexOf(errorMessage) !== -1) {
        inputError.value.splice(inputError.value.indexOf(errorMessage), 1);
      }

      regex = /^.{15,50}$/; // Device name has to be specific length
      errorMessage = "Device name has to be 15-50 characters long";
      if (!regex.test(value)) {
        if (inputError.value.indexOf(errorMessage) === -1)
          inputError.value.push(errorMessage);
      } else if (inputError.value.indexOf(errorMessage) !== -1) {
        inputError.value.splice(inputError.value.indexOf(errorMessage), 1);
      }

      console.debug(
        componentName,
        functionName,
        "inputError: ",
        inputError.value,
      );

      return inputError.value.length > 0 ? inputError.value[0] : true;
    };

    const verifyLinkingDevice = async () => {
      const functionName = "verifyLinkingDevice";
      isLinking.value = true;

      const code = route.params.code;

      console.debug(componentName, functionName, "Code: ", code);

      let challenge,
        registration,
        userId,
        username,
        deviceLinkingDocId,
        deviceLinkVerifyCodeResult,
        isLinkingSuccessful;

      deviceLinkVerifyCodeResult = await verifyDeviceLinkCode(code);
      userId = deviceLinkVerifyCodeResult.userId;
      username = deviceLinkVerifyCodeResult.username;
      deviceLinkingDocId = deviceLinkVerifyCodeResult.deviceLinkingDocId;

      if (deviceLinkVerifyCodeResult.status) {
        const response = await getChallenge(
          deviceLinkVerifyCodeResult.username,
          deviceLinkVerifyCodeResult.userId,
        );
        challenge = response.challenge;
      }

      if (challenge) {
        registration = await webAuthnCreateCredential(
          challenge,
          userId,
          deviceLinkVerifyCodeResult.username,
        );
      }

      if (registration) {
        console.debug(
          componentName,
          functionName,
          "Registration-object: ",
          registration,
        );
        isLinkingSuccessful = await linkNewDeviceToUser(
          userId,
          registration.registration.credential,
          defaultDeviceLinkingInfo.value,
          deviceName.value,
        );
      }

      if (isLinkingSuccessful) {
        console.debug(
          componentName,
          functionName,
          "isLinkingSuccessful: ",
          isLinkingSuccessful,
        );

        // Delete device linking document after successful linking
        try {
          await deleteDoc(doc(firestore, "deviceLinks", deviceLinkingDocId));
        } catch (error) {
          console.error(componentName, functionName, "Error: ", error);
        }

        try {
          await signInAnonymously(auth);
          console.debug(
            componentName,
            functionName,
            "Authenticated anonymously via Firebase",
          );
          authStore.setUserId(userId);
          authStore.setUsername(username);

          isLinking.value = false;
          linkingSuccess.value = true;
        } catch (error) {
          console.error(error);
        }
      } else {
        isLinking.value = false;
        linkingSuccess.value = false;
      }

      snackBarStore.displayNotification({
        color: linkingSuccess.value == true ? "success" : "error",
        message:
          linkingSuccess.value == true
            ? "Device linked successfully"
            : "Failed to link device",
        timeout: 2000,
      });

      if (linkingSuccess.value) {
        router.replace("/home");
      }
    };

    onMounted(() => {
      const functionName = "onMounted";
      console.info(componentName, functionName);

      defaultDeviceLinkingInfo.value = getDeviceInfo();
      dimenStore.calculateIsMobile();
      dimenStore.calculateTopNavHeight();
      dimenStore.calculateBottomNavHeight();

      containerHeight.value = dimenStore.calculateContainerHeight(true, false);

      window.addEventListener("resize", () => {
        containerHeight.value = dimenStore.calculateContainerHeight(
          true,
          false,
        );
      });
    });

    onUnmounted(() => {
      window.removeEventListener("resize", () => {
        containerHeight.value = dimenStore.calculateContainerHeight(
          true,
          false,
        );
      });
    });

    return {
      inputError,
      deviceName,
      isLinking,
      linkingSuccess,
      verifyLinkingDevice,
      windowHeight,
      vTopNavHeight: dimenStore.vTopNavHeight,
      validateText,
      defaultDeviceLinkingInfo,
      clearDeviceName,
      containerHeight,
      dimenStore,
    };
  },
};
</script>

<style scoped>
.v-input__details {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>

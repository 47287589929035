import { firestore } from "@/firebase";
import {
  collection,
  deleteDoc,
  limit,
  onSnapshot,
  query,
  updateDoc,
  where,
  getDocs,
  writeBatch,
  arrayRemove,
} from "firebase/firestore";
import { defineStore } from "pinia";
import { ref, watch } from "vue";
import { useRouter } from "vue-router";
import { useAuthStore } from "./authStore";
import { useSnackBarStore } from "./snackBarStore";
import { v4 as uuidv4 } from "uuid";
import useClipboard from "vue-clipboard3";
import createFriendRequestLink from "../utils/friendRequest/createFriendRequestLink";

const storeName = "friendStore";
const { toClipboard } = useClipboard();

export const useFriendStore = defineStore({
  id: storeName,
  state: () => ({
    router: useRouter(),
    acceptedFriendRequestDocRef: ref(null),
    acceptedFriendRequest: ref(null),
    firestoreAcceptedFriendRequestListener: ref(null),
    firestorePendingFriendRequestListener: ref(null),
    isPendingFriendRequest: ref(false),
    snackBarStore: useSnackBarStore(),
    isProcessingResponse: ref(false),
    authStore: useAuthStore(),
  }),
  actions: {
    onInit() {
      const functionName = "onInit";
      console.debug(storeName, functionName);

      // Automatically fetch user's own stories on login
      watch(
        () => this.authStore.userId,
        async (newUserId, oldUserId) => {
          console.info(
            storeName,
            functionName,
            "newUserId: ",
            this.authStore.userId,
            "oldUserId: ",
            oldUserId,
          );
          if (newUserId != null) {
            console.debug(storeName, functionName, "User logged in");
            this.createFirestoreAcceptedFriendRequestsListener();
            this.createFirestorePendingFriendRequestListener();
          } else {
            if (this.acceptedFriendRequest != null) {
              this.acceptedFriendRequest = null;
            }
            if (this.acceptedFriendRequestDocRef != null) {
              this.acceptedFriendRequestDocRef = null;
            }
            if (this.firestoreAcceptedFriendRequestListener) {
              this.firestoreAcceptedFriendRequestListener();
              this.firestoreAcceptedFriendRequestListener = null;
            }
            if (this.firestorePendingFriendRequestListener) {
              this.firestorePendingFriendRequestListener();
              this.firestorePendingFriendRequestListener = null;
            }
          }
        },
        { immediate: true },
      );
    },
    async replyFriendRequest(
      friendRequestDocRef,
      friedRequestDocData,
      isAccepted,
    ) {
      const functionName = "replyFriendRequest";
      this.isProcessingResponse = true;

      try {
        await updateDoc(
          friendRequestDocRef,
          {
            isAccepted: isAccepted,
            updatedAt: new Date().toUTCString(),
            receiverId: this.authStore.userId,
            receiverName: this.authStore.username,
            userIds: [...friedRequestDocData.userIds, this.authStore.userId],
          },
          { merge: true },
        );
        this.snackBarStore.displayNotification({
          message: `Friend request ${isAccepted ? "accepted" : "rejected"}`,
          color: "success",
          timeout: 2000,
        });
      } catch (error) {
        console.error(storeName, functionName, error);
        this.snackBarStore.displayNotification({
          message: `Error occurred when ${
            isAccepted ? "accepting" : "rejecting"
          } friend request`,
          color: "error",
          timeout: 2500,
        });
        return;
      }

      // Delete your own friend request in case you accepted someone else's
      if (
        isAccepted &&
        this.isPendingFriendRequest &&
        friedRequestDocData.creatorId != this.authStore.userId
      ) {
        // You accepted other user's friend request, delete yours
        const q = query(
          collection(firestore, "friendRequests"),
          where("creatorId", "==", this.authStore.userId),
        );
        try {
          const querySnapshot = await getDocs(q);

          const batch = writeBatch(firestore);

          querySnapshot.forEach(async (doc) => {
            batch.delete(doc.ref);
          });
          await batch.commit();
          console.debug(
            storeName,
            functionName,
            "Own friend request successfully deleted",
          );
        } catch (error) {
          console.error(
            storeName,
            functionName,
            `Error occurred when deleting own friend request: ${error}`,
          );
        }
      }

      setTimeout(() => {
        this.isProcessingResponse = false;
        this.router.replace("/");
      }, 1000);
    },
    getFriendUserId() {
      const functionName = "getFriendUserId";
      console.info(storeName, functionName);
      const friendUserId =
        this.acceptedFriendRequest.receiverId != this.authStore.userId
          ? this.acceptedFriendRequest.receiverId
          : this.acceptedFriendRequest.creatorId;
      if (friendUserId) {
        console.debug(
          storeName,
          functionName,
          `Friend's userId is: '${friendUserId}'`,
        );
        return friendUserId;
      } else {
        console.debug(storeName, functionName, "Couldn't get friend's userId");
        return null;
      }
    },
    getFriendUsername() {
      const functionName = "getFriendUsername";
      console.info(storeName, functionName);
      const friendUsername =
        this.acceptedFriendRequest.receiverName != this.authStore.username
          ? this.acceptedFriendRequest.receiverName
          : this.acceptedFriendRequest.creatorName;
      if (friendUsername) {
        console.debug(
          storeName,
          functionName,
          `Friend's username is: '${friendUsername}'`,
        );
        return friendUsername;
      } else {
        console.debug(
          storeName,
          functionName,
          "Couldn't get friend's username",
        );
        return null;
      }
    },
    async createFriendRequest() {
      const functionName = "addFriend";
      console.info(storeName, functionName);

      this.isProcessingResponse = true;

      const friendRequestCode = uuidv4();
      const result = await createFriendRequestLink(friendRequestCode);

      if (!result.status) {
        this.snackBarStore.displayNotification({
          message: result.message,
          color: "error",
        });
        return;
      }

      try {
        let link;

        switch (window.location.hostname) {
          case "localhost":
            link = `http://localhost:8081/friendRequests/${friendRequestCode}`;
            break;
          case "www.storyque.app":
            link = `https://www.storyque.app/friendRequests/${friendRequestCode}`;
            break;
          case "www.storyque.xyz":
            link = `https://www.storyque.xyz/friendRequests/${friendRequestCode}`;
            break;
          default:
            link = "";
        }

        console.debug(
          storeName,
          functionName,
          `Copying friend request's link '${link}' to clipboard`,
        );
        await toClipboard(link);
        this.snackBarStore.displayNotification({
          message: "New friend request link copied",
          color: "success",
        });
      } catch (e) {
        console.error(storeName, functionName, e);
        this.snackBarStore.displayNotification({
          message: "Failed to copy new friend request link",
          color: "error",
        });
      } finally {
        this.isProcessingResponse = false;
      }
    },
    async deleteFriendRequest() {
      const functionName = "deleteFriendRequest";
      console.info(storeName, functionName);

      this.isProcessingResponse = true;

      // Delete any previous Friend Requests
      try {
        const q = query(
          collection(firestore, "friendRequests"),
          where("creatorId", "==", this.authStore.userId),
        );
        const querySnapshot = await getDocs(q);

        const batch = writeBatch(firestore);

        if (querySnapshot.empty) {
          console.debug(functionName, "No Friend Request(s) found to delete");
        } else {
          querySnapshot.docs.forEach((doc) => {
            batch.delete(doc.ref);
          });

          await batch.commit();
          console.debug(functionName, "Friend Request(s) deleted");
          this.snackBarStore.displayNotification({
            message: `Friend request cancelled`,
            color: "success",
          });
        }
      } catch (error) {
        console.error(
          functionName,
          "Error occurred when deleting Friend Request(s): ",
          error,
        );
        this.snackBarStore.displayNotification({
          message: `Failed to cancel friend request`,
          color: "error",
        });
      } finally {
        this.isProcessingResponse = false;
      }
    },
    async unfriend() {
      const functionName = "unfriend";
      console.info(storeName, functionName);

      this.isProcessingResponse = true;

      const unfriendTargetUserId = this.getFriendUserId();
      if (!unfriendTargetUserId) {
        this.snackBarStore.displayNotification({
          message: "Unexpeted rrror occurred",
          color: "error",
        });
        this.isProcessingResponse = false;
        return;
      }

      const unfriendTargetUsername = this.getFriendUsername();
      if (!unfriendTargetUsername) {
        this.snackBarStore.displayNotification({
          message: "Unexpeted rrror occurred",
          color: "error",
        });
        this.isProcessingResponse = false;
        return;
      }

      try {
        // If any story was privately shared with the friend (who is now un-friended), make those stories private
        console.debug(
          storeName,
          functionName,
          "Removing friend's userId from stories' sharedTo-array",
        );

        const batch = writeBatch(firestore);

        // First, remove private sharing from friend's stories' by removing your userId from them -->
        let q = query(
          collection(firestore, "stories"),
          where("creatorId", "==", unfriendTargetUserId),
          where("sharedTo", "array-contains", this.authStore.userId),
        );

        let querySnapshot = await getDocs(q);

        querySnapshot.forEach((docSnapshot) => {
          const docRef = docSnapshot.ref;
          batch.update(docRef, {
            sharedTo: arrayRemove(this.authStore.userId),
          });
        });
        // <--

        // Second, remove private sharing from your stories' by removing your friend's userId from them -->
        q = query(
          collection(firestore, "stories"),
          where("creatorId", "==", this.authStore.userId),
          where("sharedTo", "array-contains", unfriendTargetUserId),
        );

        querySnapshot = await getDocs(q);

        querySnapshot.forEach((docSnapshot) => {
          const docRef = docSnapshot.ref;
          batch.update(docRef, {
            sharedTo: arrayRemove(unfriendTargetUserId),
          });
        });
        // <--

        // Commit the batch
        await batch.commit();

        // Delete Friend Request -document
        await deleteDoc(this.acceptedFriendRequestDocRef);
        this.acceptedFriendRequest = null;
        this.acceptedFriendRequestDocRef = null;
        this.snackBarStore.displayNotification({
          message: `'${unfriendTargetUsername}' unfriended successfully`,
          color: "success",
        });
      } catch (error) {
        console.debug(storeName, functionName, error);
        this.snackBarStore.displayNotification({
          message: `Error occurred when unfriending '${unfriendTargetUsername}'`,
          color: "error",
        });
      } finally {
        this.isProcessingResponse = false;
      }
    },
    createFirestoreAcceptedFriendRequestsListener() {
      const functionName = "createFirestoreAcceptedFriendRequestsListener";
      console.debug(storeName, functionName);

      /**
       * Query for accepted friend request
       */
      const q = query(
        collection(firestore, "friendRequests"),
        where("userIds", "array-contains", this.authStore.userId),
        where("isAccepted", "==", true),
        limit(1),
      );

      this.firestoreAcceptedFriendRequestListener = onSnapshot(
        q,
        (querySnapshot) => {
          if (querySnapshot.empty) {
            console.debug(
              storeName,
              functionName,
              "No accepted friend request found",
            );
            this.acceptedFriendRequestDocRef = null;
            this.acceptedFriendRequest = null;
          } else {
            console.debug(
              storeName,
              functionName,
              "Accepted friend request found",
            );
            this.acceptedFriendRequestDocRef = querySnapshot.docs[0].ref;
            this.acceptedFriendRequest = querySnapshot.docs[0].data();
            console.debug(storeName, functionName, this.acceptedFriendRequest);
          }
        },
      );
    },
    createFirestorePendingFriendRequestListener() {
      const functionName = "createFirestorePendingFriendRequestListener";
      console.info(storeName, functionName);

      /**
       * Query for pending friend request
       */
      const q = query(
        collection(firestore, "friendRequests"),
        where("creatorId", "==", this.authStore.userId),
        where("isAccepted", "==", "pending"),
        limit(1),
      );

      this.firestorePendingFriendRequestListener = onSnapshot(
        q,
        (querySnapshot) => {
          if (querySnapshot.empty) {
            console.debug(
              storeName,
              functionName,
              "No pending friend request found",
            );
            if (this.isPendingFriendRequest) {
              this.isPendingFriendRequest = false;
            }
          } else {
            console.debug(
              storeName,
              functionName,
              "Pending friend request found",
            );
            if (!this.isPendingFriendRequest) {
              this.isPendingFriendRequest = true;
            }
          }
        },
      );
    },
  },
});

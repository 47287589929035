import { ref } from "vue";
import { defineStore } from "pinia";

const storeName = "pwaStore";

export const usePwaStore = defineStore({
  id: storeName,
  state: () => ({
    initialized: ref(false),
    installedApps: ref(null),
    appInstalled: ref(null),
    displayMode: ref(null),
    deferredPrompt: ref(null),
  }),
  actions: {
    onInit() {
      const functionName = "onInit";
      console.info(storeName, functionName);

      this.checkStandaloneMode();
      this.checkInstalledRelatedApps()
        .then(() => {
          if (!this.initialized) {
            this.initialized = true;
          }
        })
        .catch((error) => {
          console.error(storeName, functionName, "Error:", error);
          this.initialized = true;
        });
    },
    async checkInstalledRelatedApps() {
      const functionName = "checkInstalledRelatedApps";
      console.info(storeName, functionName);

      if ("getInstalledRelatedApps" in navigator) {
        try {
          const apps = await navigator.getInstalledRelatedApps();
          console.debug(storeName, functionName, "Installed apps:", apps);
          this.installedApps = apps;
        } catch (error) {
          console.error("Error checking installed related apps:", error);
        }
      } else {
        console.warn(
          "getInstalledRelatedApps is not supported in this browser.",
        );
      }
    },
    checkStandaloneMode() {
      const functionName = "checkStandaloneMode";
      console.info(storeName, functionName);
      if (window.matchMedia("(display-mode: standalone)").matches) {
        console.debug(storeName, functionName, "Running in Standalone-mode");
        this.displayMode = "standalone";
      } else {
        console.debug(
          storeName,
          functionName,
          "Not running in Standalone-mode",
        );
        this.displayMode = "browser";
      }
    },
    async installPWA() {
      const functionName = "installPWA";
      console.info(storeName, functionName);

      if (this.deferredPrompt) {
        this.deferredPrompt.prompt();
        const { outcome } = await this.deferredPrompt.userChoice;
        if (outcome === "accepted") {
          console.log(storeName, functionName, "User accepted the A2HS prompt");
        } else {
          console.log(
            storeName,
            functionName,
            "User dismissed the A2HS prompt",
          );
        }
        this.deferredPrompt = null;
      }
    },
    handleAppInstalled(event) {
      const functionName = "handleAppInstalled";
      console.info(storeName, functionName);
      console.debug(storeName, functionName, "event:", event);
      this.appInstalled = event.returnValue;

      this.checkStandaloneMode();
    },
    checkIfAppInstalled() {
      const functionName = "checkIfAppInstalled";
      console.info(storeName, functionName);
      if (this.displayMode == "standalone") {
        console.debug(storeName, functionName, "DisplayMode is 'standalone'");
        return true;
      } else if (this.appInstalled) {
        console.debug(
          storeName,
          functionName,
          "appinstalled-event's returnValue is TRUE",
        );
        return true;
      } else {
        console.debug(
          storeName,
          functionName,
          "App most probably not installed",
        );
        return false;
      }
    },
  },
});

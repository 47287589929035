import { openDB } from "idb";
import { defineStore } from "pinia";
import { ref } from "vue";

const componentName = "visibilityStore";
const DB_NAME = "visibilityDB";
const DB_VERSION = 1;
const STORE_NAME = "visibilityStore";

export const useVisibilityStore = defineStore({
  id: componentName,
  state: () => ({
    visibilityState: ref(document.visibilityState),
    db: null,
  }),
  actions: {
    async onInit() {
      const functionName = "onInit";
      console.info(componentName, functionName);
      await this.openDB();
      this.writeToIndexedDB(document.visibilityState);
      this.visibilityStateListener();
      // this.handleVisibilityStateRequestFromSW();
    },
    // handleVisibilityStateRequestFromSW() {
    //   const functionName = "handleVisibilityStateRequestFromSW";
    // self.addEventListener("message", async (event) => {
    //   const data = event.data;
    //   console.debug(
    //     componentName,
    //     functionName,
    //     "Message received from service worker: ",
    //     data,
    //   );
    //     if (data.type === "getVisibilityState") {
    //       const visibilityState = await this.db.get(STORE_NAME, 1);
    //       // Send the response back to the service worker
    //       const responseData = { message: visibilityState };

    //       // Send the response back to the service worker
    //       event.source.postMessage(responseData);
    //     }
    //   });
    // },
    visibilityStateListener() {
      const functionName = "visibilityStateListener";
      document.addEventListener("visibilitychange", async () => {
        this.visibilityState = document.visibilityState;
        console.debug(
          componentName,
          functionName,
          "visibility state: ",
          this.visibilityState,
        );
        this.writeToIndexedDB(this.visibilityState);
      });
    },
    async openDB() {
      // const functionName = "openDB";
      this.db = await openDB(DB_NAME, DB_VERSION, {
        upgrade(db) {
          db.createObjectStore(STORE_NAME, {
            keyPath: "id",
            autoIncrement: false,
          });
        },
      });
    },
    async writeToIndexedDB(data) {
      // const functionName = "writeToIndexedDB";
      await this.db.put(STORE_NAME, { data, id: 1 });
    },
  },
});

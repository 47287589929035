import { useAuthStore } from "@/stores/authStore";

async function webAuthnSendCredential(challenge, credential, userId, username) {
  console.log("webAuthSendCredential");
  console.debug(
    "webAuthSendCredential",
    "challenge: ",
    challenge,
    ", credential: ",
    credential,
    ", userId: ",
    userId,
    ", username: ",
    username,
  );

  const authStore = useAuthStore();

  try {
    let response = await fetch(
      window?.location?.hostname == "localhost"
        ? "http://localhost:5001/storyque-fee51/us-central1/api/registerUser"
        : "https://api-efmmhglqbq-uc.a.run.app/registerUser",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          challenge: challenge,
          registration: credential,
          userId: userId,
          username: username,
        }),
      },
    );

    if (response.ok) {
      response = await response.json();
      console.debug("webAuthSendCredential", "response", response);

      if (!response.status) {
        console.warn("webAuthnSendCredential", `status: ${response.status}`);
        return null;
      } else {
        authStore.setUserId(response.userId);
        authStore.setUsername(response.username);
      }
      return { userId: response.userId, username: response.username };
    } else {
      return null;
    }
  } catch (error) {
    console.error("webAuthSendCredential", error);
    return null;
  }
}

export default webAuthnSendCredential;

import {
  collection,
  query,
  where,
  getDocs,
  doc,
  deleteDoc,
} from "firebase/firestore";
import { firestore } from "@/firebase";
import { useAuthStore } from "@/stores/authStore";

async function deleteUserCredentials() {
  const functionName = "deleteUserCredentials";
  console.info(functionName);

  const authStore = useAuthStore();

  const credentialsCol = "credentials";

  try {
    // Define the collection and the query
    const colRef = collection(firestore, credentialsCol);
    const q = query(colRef, where("userId", "==", authStore.userId));

    // Execute the query
    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      console.error(functionName, "No credential-document(s) found");
      return false;
    }

    // Iterate over the documents and delete each one
    const deletePromises = querySnapshot.docs.map(async (document) => {
      await deleteDoc(doc(firestore, credentialsCol, document.id));
    });

    // Wait for all delete operations to complete
    await Promise.all(deletePromises);

    console.info(functionName, "Credential-document(s) deleted successfully");
    return true;
  } catch (error) {
    console.error(
      functionName,
      "Error deleting credential-document(s):",
      error,
    );
    return false;
  }
}

export default deleteUserCredentials;

import { client } from "@passwordless-id/webauthn";

async function webAuthnLogin(challenge) {
  console.log("webAuthnLogin");
  console.debug("webAuthnLogin", "challenge", challenge);

  // WebAuthn works only on domains and localhost, not with IP addresses
  if (!window.PublicKeyCredential) {
    console.info(
      "webAuthnLogin",
      "Public-key credentials (WebAuthn) not supported",
    );
    return {
      status: false,
      message: "WebAuthn not supported on this device",
    };
  }

  try {
    /**
     * authentication-object example:
    {
      "credentialId": "3924HhJdJMy_svnUowT8eoXrOOO6NLP8SK85q2RPxdU",
      "authenticatorData": "SZYN5YgOjGh0NBcPZHZgW4_krrmihjLHmVzzuoMdl2MFAAAAAQ==",
      "clientData": "eyJ0eXBlIjoid2ViYXV0aG4uZ2V0IiwiY2hhbGxlbmdlIjoiNTY1MzViMTMtNWQ5My00MTk0LWEyODItZjIzNGMxYzI0NTAwIiwib3JpZ2luIjoiaHR0cDovL2xvY2FsaG9zdDo4MDgwIiwiY3Jvc3NPcmlnaW4iOmZhbHNlLCJvdGhlcl9rZXlzX2Nhbl9iZV9hZGRlZF9oZXJlIjoiZG8gbm90IGNvbXBhcmUgY2xpZW50RGF0YUpTT04gYWdhaW5zdCBhIHRlbXBsYXRlLiBTZWUgaHR0cHM6Ly9nb28uZ2wveWFiUGV4In0=",
      "signature": "MEUCIAqtFVRrn7q9HvJCAsOhE3oKJ-Hb4ISfjABu4lH70MKSAiEA666slmop_oCbmNZdc-QemTv2Rq4g_D7UvIhWT_vVp8M="
    }
     */
    const authentication = await client.authenticate([], challenge, {
      authenticatorType: "auto",
      userVerification: "required",
      timeout: 60000,
    });

    let response = await fetch(
      window?.location?.hostname == "localhost"
        ? "http://localhost:5001/storyque-fee51/us-central1/api/loginUserV2"
        : "https://api-efmmhglqbq-uc.a.run.app/loginUserV2",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          authentication: authentication,
          challenge: challenge,
        }),
      },
    );

    if (response.ok) {
      response = await response.json();
      console.debug("webAuthnLogin", "response", response);
      if (!response.status) {
        console.debug("webAuthnLogin", `status: ${response.status}`);
        return { status: response.status, message: response.message };
      } else {
        console.debug("webAuthnLogin", "Login successful");
        return {
          status: response.status,
          message: "Successfully logged in",
          username: response.username,
          userId: response.userId,
        };
      }
    } else {
      return {
        status: false,
        message: "Error occurred when logging in, please try",
      };
    }
  } catch (error) {
    console.error("webAuthnLogin", error);
    return {
      status: false,
      message: "Error occurred when logging in, please try",
    };
  }
}

export default webAuthnLogin;

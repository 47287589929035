async function requestOtp(email) {
  const functionName = "requestOtp";
  console.info(functionName);

  try {
    const apiUrl = `http${
      process?.env?.NODE_ENV === "production" ? "s" : ""
    }://${
      process?.env?.NODE_ENV === "production"
        ? "api-efmmhglqbq-uc.a.run.app/"
        : "localhost:5001/storyque-fee51/us-central1/api/"
    }getOtp`;

    let response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json", // Specify that you are sending JSON data
      },
      body: JSON.stringify({ email: email }),
    });
    return { status: response.status, statusText: response.statusText };
  } catch (error) {
    console.error(functionName, "Error:", error);
    return false;
  }
}

export default requestOtp;

import { defineStore } from "pinia";
import { ref } from "vue";

/**
 * Store to-be-viewed story to update AppBar's title
 * and load the story in StoryViewer, simultaneously
 */

export const useCurrentViewedStoryStore = defineStore({
  id: "currentViewedStory",
  state: () => ({
    story: ref(null),
  }),
  actions: {
    setStory(story) {
      console.log("currentViewedStory", "setStory");
      this.story = story;
      console.log("currentViewedStory", "setStory", "story: ", this.story);
    },
    unsetStory() {
      console.log("currentViewedStory", "unsetStory");
      this.story = null;
    },
  },
});

import { firestore } from "@/firebase";
import { collection, getDocs, query, where } from "firebase/firestore";

const componentName = "getFriendRequest";

/**
 * code - UUIDv4 used for generating friend request URL to create friendship between two users
 */

export default async function (code) {
  console.info(componentName);
  console.debug(componentName, "code: ", code);

  try {
    const q = query(
      collection(firestore, "friendRequests"),
      where("code", "==", code),
      where("isAccepted", "==", "pending"),
    );

    const querySnapshot = await getDocs(q);

    console.debug(componentName, "docs: ", querySnapshot.docs.length);

    if (querySnapshot.empty) {
      console.debug(
        componentName,
        "No Friend Request -document found for code: ",
        code,
      );
      return {};
    } else {
      console.debug(
        componentName,
        "Friend Request -document found for code: ",
        code,
      );

      return {
        ref: querySnapshot.docs[0].ref,
        data: querySnapshot.docs[0].data(),
      };
    }
  } catch (error) {
    console.error(error);
    return {};
  }
}

import { firestore } from "@/firebase";
import { useAuthStore } from "@/stores/authStore";
import { addDoc, collection } from "firebase/firestore";

/**
 * code - UUIDv4 used for generating device linking URL to link device to specific user
 */

export default async function (code) {
  console.info("createDeviceLinkCode");
  console.debug("createDeviceLinkCode", "code: ", code);
  const authStore = useAuthStore();

  try {
    const deviceLinksCollectionRef = collection(firestore, "deviceLinks");

    const deviceLinkDocRef = await addDoc(deviceLinksCollectionRef, {
      code: code,
      createdAt: new Date().toUTCString(),
      userId: authStore.userId,
    });
    console.log(
      "createDeviceLinkCode",
      "Device link code added to deviceLink-document: ",
      deviceLinkDocRef.id,
    );
    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
}

import { firestore } from "@/firebase";
import {
  collection,
  getDocs,
  limit,
  query,
  where,
  getDoc,
  doc,
} from "firebase/firestore";

/**
 * code - UUIDv4 used for generating device linking URL to link device to specific user
 */

const functionName = "verifyDeviceLinkCode";

/**
 *
 * @param {*} code Code taken from the URL
 * @returns Object containing status of the verification process, userId and username fetched from the device linking document
 * with the corresponding code, device linking document ID for reference
 */

export default async function (code) {
  console.info(functionName);

  console.debug(functionName, "Code: ", code);

  try {
    let q = query(
      collection(firestore, "deviceLinks"),
      where("code", "==", code),
      limit(1),
    );

    let querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      console.debug(functionName, "No user-documents found for code: ", code);
      return {
        status: false,
        userId: null,
        username: null,
      };
    } else {
      console.debug(functionName, "User-documents found for code: ", code);

      const deviceLinkDocRef = querySnapshot.docs[0];
      const deviceLinkDocData = deviceLinkDocRef.data();
      const deviceLinkDocId = deviceLinkDocRef.id;

      console.debug(functionName, "deviceLinkDocData: ", deviceLinkDocData);

      let result = {
        status: true,
        userId: null,
        username: null,
        deviceLinkDocId: deviceLinkDocId,
      };

      // Get username

      try {
        const userDocRef = doc(firestore, "users", deviceLinkDocData.userId);
        const userDocSnapshot = await getDoc(userDocRef);

        if (userDocSnapshot.exists()) {
          const userDocData = userDocSnapshot.data();
          console.debug(functionName, "userDocData: ", userDocData);

          console.debug(
            functionName,
            `Username found for userId: ${userDocData.username}`,
          );
          result.username = userDocData.username;
          result.userId = userDocRef.id;
        } else {
          console.debug(
            functionName,
            `No username found for userId: ${result.userId}`,
          );
        }
      } catch (error) {
        console.error(functionName, `Error: ${error}`);
      }

      console.debug(functionName, "result: ", result);

      return result;
    }
  } catch (error) {
    console.error(error);
    return {
      status: false,
      userId: null,
      username: null,
      deviceLinkDocId: null,
    };
  }
}

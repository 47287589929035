const getDeviceInfo = () => {
  const userAgent = navigator.userAgent;
  let os = "Unknown OS";
  let browser = "Unknown Browser";

  // Detect OS
  if (userAgent.indexOf("Win") !== -1) os = "Windows";
  if (userAgent.indexOf("Mac") !== -1) os = "MacOS";
  if (userAgent.indexOf("X11") !== -1) os = "UNIX";
  if (userAgent.indexOf("Linux") !== -1) os = "Linux";
  if (/Android/.test(userAgent)) os = "Android";
  if (/iPhone|iPad|iPod/.test(userAgent)) os = "iOS";

  // Detect Browser
  if (/Chrome/.test(userAgent) && /Google Inc/.test(navigator.vendor)) {
    browser = "Chrome";
  } else if (
    /Safari/.test(userAgent) &&
    /Apple Computer/.test(navigator.vendor)
  ) {
    browser = "Safari";
  } else if (/Firefox/.test(userAgent)) {
    browser = "Firefox";
  } else if (/MSIE|Trident/.test(userAgent)) {
    browser = "Internet Explorer";
  } else if (/Edge/.test(userAgent)) {
    browser = "Edge";
  } else if (/Opera|OPR/.test(userAgent)) {
    browser = "Opera";
  }

  return `${os}, ${browser}, ${new Date().toLocaleDateString()} ${new Date().toLocaleTimeString()}`;
};

export default getDeviceInfo;

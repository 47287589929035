const functionName = "getChallenge";

async function getChallenge() {
  console.info(functionName);

  try {
    let response = await fetch(
      process?.env?.NODE_ENV === "development" ||
        window?.location?.hostname === "localhost"
        ? "http://localhost:5001/storyque-fee51/us-central1/api/getChallenge"
        : "https://api-efmmhglqbq-uc.a.run.app/getChallenge",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Specify that you are sending JSON data
        },
      },
    );
    if (response.ok) {
      response = await response.json();
      console.debug(functionName, "response:", response);
      return response;
    } else {
      console.error(functionName, "response:", response);
      return false;
    }
  } catch (error) {
    console.error(functionName, "error:", error);
    return null;
  }
}

export default getChallenge;

import { firestore } from "@/firebase";
import { addDoc, collection } from "firebase/firestore";

export default async function (story) {
  console.info("createFirestoreStoryDoc");
  const docRef = collection(firestore, "stories");

  try {
    const doc = await addDoc(docRef, story);
    console.log("Document created with ID:", doc.id);
    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
}
